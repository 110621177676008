import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Apollo, QueryRef } from 'apollo-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { BusyService } from 'src/app/ui';
import { ListIncidents } from '../incident.graphql';
import { IncidentsQueryVariables } from '../incidents-query-variables';

export class IncidentsListDataSource implements DataSource<any> {
  private incidentsSubject = new BehaviorSubject<any[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  private _sortOriginal: any;

  private queryRef: QueryRef<any>;
  private variables = new BehaviorSubject<IncidentsQueryVariables>({
    sort: {
      active: 'id',
      direction: 'desc',
    },
  });

  constructor(private apollo: Apollo, private busy: BusyService) {
    this.busy.busy();
    this.queryRef = this.apollo.watchQuery<any>({
      query: ListIncidents,
      variables: {},
      errorPolicy: 'all',
    });

    this.queryRef.valueChanges.subscribe((result: any) => {
      console.log('value changes');
      console.log(result);
      this.incidentsSubject.next(result.data.retrieveProductIncidents);

      this.variables.subscribe(
        (variables: any) => {
          console.log(variables);
          this.busy.busy();
          this.queryRef.fetchMore({
            variables: variables,
            updateQuery: (prev, { fetchMoreResult }) => {
              this.incidentsSubject.next(fetchMoreResult.retrieveProductIncidents);
              this.busy.finished();
            },
          });
        },
        (error) => {
          this.busy.finished();
        }
      );
    });
  }

  set sort(sort: any) {
    this._sortOriginal = sort;

    this.variables.next(
      Object.assign(this.variables.value, {
        sort: sort,
      })
    );
  }
  set filter(filter: any) {
    this.variables.next(Object.assign(this.variables.value, filter));
  }

  get sort() {
    return this._sortOriginal;
  }

  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.incidentsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.loadingSubject.complete();
    this.incidentsSubject.complete();
  }

  get data() {
    return this.incidentsSubject.value;
  }
}
