<!-- <div class="col">
<div *ngIf="loading">
  Loading...
</div>
<div *ngIf="error">
  Error :(
</div> -->

<app-title title="Distributors" [template]="actions"></app-title>
<ng-template #actions>
    <div class="ml-0 ml-md-2 action-group">	
        <a class="btn btn-primary" uiSref=".new">New Distributor</a>
    </div>
</ng-template>


<table class="table table-striped">
  <thead>
    <tr class="sortable">
      <th (click)="sort('name')">Name <span [class]="sortClass('name')"></span></th>
      <th (click)="sort('country')">Country <span [class]="sortClass('country')"></span></th>
      <th (click)="sort('status')">Status <span [class]="sortClass('status')"></span></th>
      <th (click)="sort('created')">Created <span [class]="sortClass('created')"></span></th>
      <th></th>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let distributor of distributors">
      <td>{{ distributor.name }}</td>
      <td>{{ distributor.country }}</td>
      <td>{{ getStatusName(distributor.status) }}</td>
      <td>{{ distributor.created | date : 'd LLLL yyyy'}}</td>
      <td class="text-right actions"><a class="btn btn-primary" uiSref=".detail" [uiParams]="{id: distributor.id}">View</a></td>
    </tr>
  </tbody>


</table>
<!-- <ul class="list-group" *ngIf="distributors">
  <li class="list-group-item" *ngFor="let distributor of distributors">
    <p><a uiSref=".detail" [uiParams]="{id: distributor.id}">{{distributor.id}}: {{distributor.name}}</a></p>
  </li>
</ul> -->