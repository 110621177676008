export const environment = {
  production: false,
  membrs: {
    login: "https://bladonmt-dev.my.memb.rs",
    api: "api.memb.rs",
    apiProtocol: "https://",
    graphQL: "https://api.memb.rs/graphql",
  },
  graphql: "bknstz2ouzcn7dz7trk5pccdda.appsync-api.eu-west-1.amazonaws.com",
  api_key: "da2-gvb6mn7ecrchnhenkgx53qhv34",
  mapbox: {
    key: "pk.eyJ1Ijoiam9yZGFuY2hpdHR5IiwiYSI6ImNqcXJ6bnFxdTAzZXk0OHFoZ2U3cXNod3gifQ.BZn0GsVOPBNZzFLL9iICKQ",
    style: "mapbox://styles/jordanchitty/cjqrzxow30a3w2smdjfpz8l0i",
  },
  filestack: "ADFgKR8OMTVur7o7iAIbrz",
};
