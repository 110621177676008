import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BusyService {
  
  private _show:boolean = false;

  constructor() { }

  busy(){
    console.log('now busy')
    this._show = true
  }

  finished(){
    console.log('now finished')
    this._show = false
  }

  get show(){
    return this._show
  }
}
