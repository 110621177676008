import { Component, OnDestroy, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Transition, UIRouter } from '@uirouter/angular'
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms'
import _ from 'underscore'
import { ToastrService } from 'ngx-toastr';
import { Statuses, Permissions } from '../../data'
import { MembrsService } from '@nakedcreativity/membrs-angular-helper'
import { DistributorsService } from '../../distributors'
import { BusyService } from '../../ui'
// Queries at the end of this file

@Component({
  selector: 'app-user-details',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})

export class UserDetailComponent implements OnInit {

  public userForm: FormGroup
  private _distributors:any = [];

  constructor(private apollo: Apollo, private transition: Transition, private fb: FormBuilder, private toastr: ToastrService, private membrsService: MembrsService, private distributorsService: DistributorsService, private router: UIRouter, private busy: BusyService) {

  }

  ngOnInit() {

    this.userForm = this.fb.group({
      firstname: ['', Validators.required],
      surname: ['', Validators.required],
      email: ['', Validators.required],
      permission: ['', Validators.required]
    })

    if(this.admin){
      this.userForm.addControl('distributor', new FormControl({}, Validators.required))
    }
    
    if (this.transition.params().id) {

      this.busy.busy()
      this.userForm.addControl('status', new FormControl('', Validators.required))

      this.apollo.watchQuery({
        query: queryUser,
        variables: {
          id: this.transition.params().id
        },
        errorPolicy: 'all'
      })
      .valueChanges.subscribe((result: any) => {
        this.userForm.patchValue(result.data.retrieveUser)
        this.busy.finished()
      });
    }

  }

  submit() {
    
    this.busy.busy()
    var mutation: any;
    var variables: any = {
      input: this.userForm.value
    }
    if(this.admin){
      variables.input.distributor = {
        id: variables.input.distributor.id
      }
    }
    

    if (!this.transition.params().id) {
      mutation = newUser
    } else {
      mutation = updateUser
      variables.id = this.transition.params().id
    }

    this.apollo.mutate({
      mutation: mutation,
      variables: variables,
      refetchQueries: ['retrieveUsers', 'retrieveUser']
    }).subscribe((result:any) => {
      this.toastr.success('Success')
      this.router.stateService.go('users')
      this.busy.finished()
    }, (error) => { 
      this.toastr.error(error.message)
      console.log('there was an error sending the query', error);
      this.busy.finished()
    });

  }

  get title(){
    if (this.transition.params().id)
      return 'Edit user'

    return 'New user'
  }

  get edit(){
    return this.transition.params().id
  }

  get permissions(){    
    return _.filter(Permissions, (permission, index)=>{
      return index >= this.membrsService.profile.data_permission
    })
  }
  
  get statuses(){
    return Statuses
  }

  get admin(){
    return this.membrsService.profile.data_permission == 1
  }

  get distributors(){
    return this.distributorsService.distributors
  }

  distributorCompare(c1,c2){
    return c1 && c2 ? c1.id === c2.id : c1 === c2
  }

}

const queryUser = gql`
query user($id: ID!){
  retrieveUser(id: $id) {
    id
    firstname
    surname
    email
    permission
    distributor {
      id
    }
    status
  }
}`

const newUser = gql`
mutation createUser($input: UserInput!){
  createUser(input: $input){
    id
    firstname
    surname
    email
    permission
    distributor {
      id
    }
  }
}
`

const updateUser = gql`
mutation updateUser($input: UserInput!, $id: ID!){
  updateUser(input: $input, id: $id){
    id
    firstname
    surname
    email
    permission
    distributor {
      id
    }
    status
  }
}
`