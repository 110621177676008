import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { Uppy } from '@uppy/core';
import { ToastrService } from 'ngx-toastr';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

const DragDrop = require('@uppy/drag-drop')
const ProgressBar = require('@uppy/progress-bar')
const AwsS3 = require('@uppy/aws-s3')
const FileInput = require('@uppy/file-input')

@Component({
  selector: 'file-upload-2',
  templateUrl: './file-upload-2.component.html',
  styleUrls: ['./file-upload-2.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FileUpload2Component implements AfterViewInit {

  @ViewChild('btn', {read: ElementRef, static:true}) btn: ElementRef
  @ViewChild('progress', {read: ElementRef, static:true}) progress: ElementRef
  
  private uppy: any
  
  @Input() id;
  @Input() type;
  @Output() upload = new EventEmitter();
  
  
  constructor(private toastr: ToastrService, private apollo: Apollo) { }

  ngAfterViewInit(){

    this.uppy = new Uppy({debug: true, autoProceed: true})
    var apollo = this.apollo
    var id = this.id
    var type = this.type 
    var toastr = this.toastr

    this.uppy
      .use(FileInput, { target: this.btn.nativeElement, replaceTargetContent: true })
      //.use(DragDrop, {target: '.uppy'})
      .use(AwsS3, {
        getUploadParameters(file){

          var variables:any = {
            filename: file.name
          }

          return new Promise((resolve, reject)=>{

            apollo.mutate({
              mutation: UploadFile,
              variables: variables
            }).subscribe((result:any) => {

              if(!result.data.uploadFile){
                reject({
                  message: 'Invalid response'
                })
              }else {
                result.data.uploadFile.fields = []
                result.data.uploadFile.headers = []

                resolve(result.data.uploadFile)
              }
            },(error) => {
              console.log(error)
              reject(error)
            });
      
          })
        }
      })
      .use(ProgressBar, {target: this.progress.nativeElement, hideAfterFinish: false}) 


    this.uppy.on("complete", result => {
      if(result.successful.length > 0){
        toastr.success('Successful upload!');
        
        result.successful.forEach(file => {

          //console.log(file)
          this.upload.emit({
            url: file.uploadURL,
            name: file.name,
            extension: file.extension,
            size: file.size
          })
        })
        
      }
      if(result.failed.length > 0){
        toastr.error('Failed to upload file');

      }

    })
  }


}


const UploadFile = gql`
mutation uploadFile($filename: String!){
  uploadFile(filename: $filename){
    method
    url
    fields
    headers
  }
}
`