import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';

import { FormBuilder, FormGroup, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms'
import _ from 'underscore'
import { ToastrService } from 'ngx-toastr';
// Queries at the end of this file
import {NgbDateAdapter, NgbDateStruct, NgbDateNativeAdapter, NgbDatepicker} from '@ng-bootstrap/ng-bootstrap';
import { BusyService, ConfirmService } from '../../ui'
import { UIRouter, Transition } from '@uirouter/angular'
//import { MembrsService } from '@nakedcreativity/membrs-angular-helper'
import { IncidentsService } from '../incidents.service'
import mapboxgl from 'mapbox-gl'
import GeoJSON from 'mapbox-gl'
import { MarkerStyle } from '../../data'
import { environment } from 'src/environments/environment';
const filesize = require('filesize')
import { severities, workpackages, subsystems } from '../variables'

import { createProductIncident, ListWarranties, RetrieveIncident, updateProductIncident } from '../incident.graphql'
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-new-incident',
  templateUrl: './new-incident.component.html',
  styleUrls: ['./new-incident.component.scss'],
  providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}]

})
export class NewIncidentComponent implements OnInit {

  public incidentForm: FormGroup
  private _generators:any = []


  attachments: FormArray = new FormArray([])
  photos: FormArray = new FormArray([])
  //parts: FormArray = new FormArray([])
  error: any;
  map: mapboxgl.Map;
  private _markers: GeoJSON.FeatureCollection<GeoJSON.Point>
  _markerSource: any;
  public generator: any;
  dp: NgbDatepicker

  private _submittedIncidentId: any = null
  private _subscriptions:Array<Subscription> = []

  constructor(private apollo: Apollo, private fb: FormBuilder, private toastr: ToastrService, private incidentsService: IncidentsService, private busy: BusyService, private router: UIRouter, private confirmService: ConfirmService, private transition: Transition) { }

  ngOnInit() {


    console.log(this.transition)
    this.incidentForm = this.fb.group({
      customer: this.fb.group({
        name: ['', [Validators.required, Validators.maxLength(250)]]
        // account: ['', [Validators.maxLength(50)]]
      }),
      generator_id: ['', Validators.required],
      operating_conditions: ['',Validators.required],
      short_term_fix: [''],
      run_hours: ['', Validators.required],
      run_cycles: ['', Validators.required],
      //existing_pir: ['', Validators.pattern('[0-9]')],
      incident_date: ['', Validators.required],
      workpackage: ['', Validators.required],
      incident_description: ['', [Validators.maxLength(2500), Validators.required]],
      troubleshooting_efforts: ['', Validators.maxLength(2500)],
      corrective_action: ['', Validators.maxLength(2500)],
      attachments: this.attachments,
      photos: this.photos,
      parts: ['', Validators.maxLength(2500)],
      severity: ['', Validators.required],
      sub_system: ['']
    })



    this.busy.busy()
    this._subscriptions.push(this.apollo
      .watchQuery({
        query: ListWarranties,
        errorPolicy: 'all'
      })
      .valueChanges.subscribe((result:any) => {

        console.log('list warranties')
        console.log(result.data)
        if(result.data && result.data.retrieveWarranties){
          this._generators = result.data.retrieveWarranties
        }
        this.error = result.error;
        this.busy.finished()

        // set first generator as selected
        if(this._generators.length > 0){
          
          if(this.transition.params().generator){

            this._generators.forEach((generator, index)=>{
              if(generator.generator_serial == this.transition.params().generator){
                this.generator = generator
                this.generatorSelectChange(this._generators[index])
              }
            })

            this.incidentForm.patchValue({
              generator_id: this.transition.params().generator
            })
          }

        }



        if(this.transition.params().id){

          this._subscriptions.push(this.apollo.watchQuery({
            query: RetrieveIncident,
            variables: {
              id: this.transition.params().id
            }
          })
          .valueChanges.subscribe((result:any) => {
  
            var incident = result.data.retrieveProductIncident
            console.log(incident)
            this._generators.forEach((generator, index)=>{
  
              console.log('each generator', generator.id)
  
              if(generator.id == incident.generator.id){
                this.generator = generator
                this.generatorSelectChange(this._generators[index])
              }
            })
            
            incident.attachments.forEach(attachment=>{
              this.addAttachment(attachment)
            })
  
            incident.photos.forEach(photo=>{
              this.addPhoto(photo)
            })
  
            // incident.parts.forEach(part=>{
            //   this.addPart()
            // })
            
            
            incident.incident_date = new Date(incident.incident_date)
            this.incidentForm.patchValue(incident)
            
            // navigate date picker
            if(this.dp){
              this.dp.navigateTo({
                year: incident.incident_date.year,
                month: incident.incident_date.month
              })
            }
  
  
            this.busy.finished()
          }, error =>{
            this.toastr.error(error.message)
            this.busy.finished()
          }))
  
        }


      },(error) => {
        this.toastr.error(error.message)
        this.busy.finished()
      }))

    
      

  }

  get today(){
    var date = new Date()
    
    return {
      year: date.getFullYear(),
      month: date.getMonth()+1,
      day: date.getDate()
    }

  }

  generatorSelectChange(value){
    // this.incidentForm.get('generator_id').valueChanges.pipe(debounceTime(500)).subscribe(value=>{
      setTimeout(()=>{
        this.updateGeneratorMap(value)
      }, 100)
      this.incidentForm.get('generator_id').setValue(value.generator_serial)
    // })
  }

  ngAfterViewInit(){
    mapboxgl.accessToken = environment.mapbox.key;
    console.log('after view init')
  }

  updateGeneratorMap(generator){
    if(!this.map){
      this.map = new mapboxgl.Map({
        container: 'map', // container id
        style: environment.mapbox.style, // stylesheet location
        center: [0,0], // starting position [lng, lat]
        zoom: 3, // starting zoom
        minZoom: 2
      });


      this.map.on('load', (event) => {
        this.map.addSource('markers', {
           type: 'geojson',
           data: {
            'type': 'FeatureCollection',
            'features': []
           }
        });

        this._markerSource = this.map.getSource('markers')
        if(this._markerSource)
          this._markerSource.setData(this._markers)
        this.map.addLayer({
          id: 'markers',
          source: 'markers',
          type: 'circle',
          paint: MarkerStyle
        })

    })


    }


    this._markers = {
      'type': 'FeatureCollection',
      'features': [{
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [generator.generator_long, generator.generator_lat]
        }
      }]
    }

    if(this._markerSource)
      this._markerSource.setData(this._markers)
    
    this.map.flyTo({
      center: [generator.generator_long, generator.generator_lat],
      zoom: 9
    })


  }

  submit($event){

    if($event == 'cancel'){
      return this.cancel()
    }

    this.busy.busy()
    var variables:any = {
      input: this.incidentForm.value
    }

    var mutation = createProductIncident
    if(this.transition.params().id){
      mutation = updateProductIncident
      variables.id = this.transition.params().id
    }
    
    this._subscriptions.push(
    this.apollo.mutate({
      mutation: mutation,
      variables: variables
      //refetchQueries: ['retrieveIncidents']
    }).subscribe((result:any) => {
      this.toastr.success('Success')
      this.busy.finished()
      
      if(result.data.createProductIncident){
        this._submittedIncidentId = result.data.createProductIncident.system_id
      }else {
        this.router.stateService.go('incidents')
      }
    },(error) => {
      this.toastr.error(error.message)

      this.busy.finished()
    }))
  
  }

  required(input, group?){
    
    let validators

    if(group){
      let formgroup = this.incidentForm.controls[group] as FormGroup
      validators = formgroup.controls[input].validator?.({} as AbstractControl)
    }else {
      validators = this.incidentForm.controls[input].validator?.({} as AbstractControl)
    }
    return validators?.required
  }

  get actions(){
    return [
      {
        label: 'Submit',
        action: 'submit'
      },
      {
        label: 'Cancel',
        action: 'cancel',
        class: 'btn-outline-secondary'
      }

    ]
  }

  // get admin(){
  //   return this.membrsService.profile.permission == 1
  // }

  get generators(){
    if(this._generators.length > 0){
      return this._generators
    }else {
      return null
    }
  }
  // get generator(){
  //   return this.incidentForm.get('generator_id').value
  // }

  addAttachment(file){
    this.attachments = this.incidentForm.get('attachments') as FormArray
    this.attachments.push(this.fb.control(file))
  }

  deleteAttachment(index){
    this.confirmService.confirm('Are you sure?', ()=>{
      this.attachments.removeAt(index)
    }, ()=>{})
  }

  addPhoto(file){
    this.photos = this.incidentForm.get('photos') as FormArray
    this.photos.push(this.fb.control(file))
  }

  deletePhoto(index){
    this.confirmService.confirm('Are you sure?', ()=>{
      this.photos.removeAt(index)
    }, ()=>{})
  }

  // addPart(){
  //   this.parts = this.incidentForm.get('parts') as FormArray
  //   this.parts.push(this.fb.group({
  //     number: this.fb.control('', Validators.pattern('[0-9]{7}')),
  //     name: this.fb.control('')
  //   }))
  // }

  // removePart(index){
  //   this.parts.removeAt(index)
  // }

  getFile(object){

  }

  size(value){
    return filesize(value)
  }

  
  get workpackages(){
    return workpackages
  }

  get subsystems(){
    return subsystems
  }

  get severities(){
    return severities
  }

  cancel(){
    this.confirmService.confirm('Are you sure you want to cancel this report?', ()=>{
      this.router.stateService.go('^')
    }, ()=>{})
    
  }

  get submittedIncidentId(){
    return this._submittedIncidentId
  }
  compareFn(c1: any, c2:any): boolean {     
    return c1 && c2 ? c1.id === c2.id : c1 === c2; 
  }
  ngOnDestroy(){
    this._subscriptions.forEach(subscription=>{
      subscription.unsubscribe()
    })
  }
}

