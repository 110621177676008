    <a class="navbar-brand" uiSref="dashboard"><img class="img-fluid" alt="Bladon" src="/assets/bladon-logo-white.svg"/></a>  

      <ul class="navbar-nav flex-column">
        <li class="nav-item"><a uiSrefActive="active" uiSref="publications" class="nav-link">Publications</a></li>
        <li class="nav-item"><a uiSrefActive="active" uiSref="warranty" class="nav-link">MTGs</a></li>
        <li class="nav-item"><a uiSrefActive="active" uiSref="incidents" class="nav-link">PIRs</a></li>
        <li class="nav-item" *ngIf="admin"><a uiSrefActive="active" uiSref="distributors" class="nav-link">Distributors</a></li>
        <li class="nav-item" *ngIf="distAdmin"><a uiSrefActive="active" uiSref="users" class="nav-link">Users</a></li>
        <li class="nav-item"><a href="javascript:void(0)" (click)="totara()" class="nav-link">Training</a></li>
      </ul>
   

      <ul class="navbar-nav flex-column mt-auto">
        <li class="nav-link profile" (click)="editProfile()">{{ profile?.name_firstname }} {{ profile?.name_surname }}</li>
        <li class="nav-item"><button class="btn btn-outline-primary" (click)="logout()">Logout</button></li>
      </ul>
