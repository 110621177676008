import { Component, Input,  forwardRef, Output, EventEmitter, OnInit } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms'
import * as filestack from 'filestack-js';

import _ from 'underscore'
import { environment } from 'src/environments/environment';

@Component({
  selector: 'image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: [
    './image-upload.component.scss'
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageUploadComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ImageUploadComponent),
      multi: true
    }
  ]
})

export class ImageUploadComponent implements OnInit{

  private fsClient;
  private fsOptions;

  private _image: any = {};

  @Input() width;
  @Input() height;
  @Input() outputOnly: Boolean
  @Output() upload = new EventEmitter();
  @Input() maxFiles: string = '1'

  constructor() {

  }

  ngOnInit(){
    console.log('output result', this.outputOnly)
    console.log('max files', this.maxFiles)
    this.fsClient = filestack.init(environment.filestack);
    this.fsOptions = {
      accept: ['image/*'],
      maxFiles: parseInt(this.maxFiles),
      onUploadDone: (res:any) => {
        res.filesUploaded.forEach(file=>{
          this.image = file
        })
      }
    }
  }

  uploadImage() {
    this.fsClient.picker(this.fsOptions).open()
  }

  get image() {
    return this._image;
  }

  set image(val) {
    console.log(val)
    this.upload.emit({
      url: val.url,
      name: val.filename,
      extension: val.mimetype,
      size: val.size
    })
    if(!this.outputOnly){
      this._image = val;
      this.propagateChange(this._image);
    }
  }

  writeValue(value: any) {
    this.image = value;
  }
  propagateChange = (_: any) => { };
  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }

  validate(c: FormControl) {
    if (this._image > 0)
      return true
    return false
  }

}