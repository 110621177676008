import { Component, OnInit } from '@angular/core';
import { MembrsService } from '@nakedcreativity/membrs-angular-helper'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { UserProfileComponent } from '../users'
import { UIRouter } from '@uirouter/angular'
import { NavService } from '../ui';
import { ToastrService } from 'ngx-toastr';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';
import { BusyService } from '../ui'

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  constructor(private apollo: Apollo, private membrsService: MembrsService, private modalService: NgbModal, private router: UIRouter, private navService: NavService, private toastr: ToastrService, private busy: BusyService) {

    this.router.transitionService.onStart({}, (transition)=>{
      console.log('start transition - nav')
      
      this.navService.close()
      
    })


  }

  ngOnInit() {
  }

  get profile(){
    return this.membrsService.profile
  }

  get admin(){
    if(!this.membrsService.profile)
      return false
    return this.membrsService.profile.data_permission == 1
  }
  get distAdmin(){
    if(!this.membrsService.profile)
      return false
    return this.membrsService.profile.data_permission == 2 || this.membrsService.profile.data_permission == 1
  }

  editProfile(){
    const modalRef = this.modalService.open(UserProfileComponent);
    // modalRef.componentInstance.permissions = this._publication.permissions
    // modalRef.result.then((result) => {
    //   this._publication.permissions = result
    // });
  }

  logout(){
    //console.log('clicked logout')
    
    
    this.membrsService.logout().then((url:string)=>{
      window.location.href = url
    }).catch(error=>{
      this.toastr.error(error.message)
    })
    
  }

  totara(){

    this.busy.busy()
      this.apollo.mutate({
        mutation: totara
      })
      .subscribe((result:any) => {
        //console.log(result.data)
        try {
          window.open(result.data.createTotaraToken.url)
        }catch(error){
          this.toastr.error(error.message)
        }
        this.busy.finished()
      }, error=>{
        this.busy.finished()
      });
  }

  // get flex(){
  //   if (this.distAdmin || this.admin){
  //     return 'd-xl-flex'
  //   }
  //   return 'd-lg-flex'
  // }
}


const totara = gql`
mutation createTotaraToken{
  createTotaraToken {
     url
  }
}`