import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms'
import { ImageUploadComponent } from './image-upload/image-upload.component'
import { ConfirmComponent } from './confirm/confirm.component'
import { DivValueAccessor } from './controls/div-value-accessor'
import { TitleComponent } from './title/title.component'
import { ConfirmService } from './confirm/confirm.service'
import { NavService } from './nav.service'
// pipes
import { TruncatePipe } from './pipes/truncate.pipe'
import { ArrayFilterPipe } from './pipes/array-filter.pipe';
import { BusyComponent } from './busy/busy.component'
import { BusyService } from './busy/busy.service';
import { FileUploadComponent } from './file-upload/file-upload.component'
import { FileUpload2Component } from './file-upload-2/file-upload-2.component';
import { FooterComponent } from './footer/footer.component'

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  declarations: [
    ImageUploadComponent,
    ConfirmComponent,
    TruncatePipe,
    DivValueAccessor,
    TitleComponent,
    ArrayFilterPipe,
    BusyComponent,
    FileUploadComponent,
    FileUpload2Component,
    FooterComponent
  ],
  exports: [
    ImageUploadComponent,
    ConfirmComponent,
    TruncatePipe,
    DivValueAccessor,
    TitleComponent,
    ArrayFilterPipe,
    BusyComponent,
    FileUploadComponent,
    FileUpload2Component,
    FooterComponent
  ]
})
export class UiModule {

  static forRoot(): ModuleWithProviders<UiModule> {

    return {
      ngModule: UiModule,
      providers: [
        ConfirmService,
        BusyService,
        NavService
      ]
    }
  }
}
