<app-title [title]="title" [template]="actions"></app-title>
<ng-template #actions>
    <div class="ml-0 ml-md-2 action-group">
        <button class="btn btn-primary mr-2" (click)="submit()" [disabled]="distributorForm.invalid">Submit</button>
        <a class="btn btn-outline-secondary" uiSref="distributors">Cancel</a>
    </div>
</ng-template>


<div class="col">


    <form class="form row" [formGroup]="distributorForm" autocomplete="off">

        <div class="col-md-6">
            <h3 class="form-group pt-3">Distributor details</h3>

            <div class="form-group">
                <label class="control-label">Distributor name</label>
                <input class="form-control" formControlName="name" type="text" />
            </div>

            <div class="form-group">
                <label class="control-label">Distributor internal ID</label>
                <input class="form-control" formControlName="internal_id" type="text" />
            </div>

            <div class="form-group">
                <label class="control-label">Contact email</label>
                <input class="form-control" formControlName="contact_email" type="text" />
            </div>

            <div class="form-group">
                <label class="control-label">Contact phone</label>
                <input class="form-control" formControlName="contact_phone" type="text" />
            </div>

            <div class="form-group">
                <label class="control-label">Contact name</label>
                <input class="form-control" formControlName="contact_name" type="text" />
            </div>

            <div class="form-group">
                <label class="control-label">Head Office phone</label>
                <input class="form-control" formControlName="head_office_phone" type="text" />
            </div>

            <h3 class="form-group pt-3">Options</h3>

            <!-- <div class="form-group">
          <label class="control-label">Market</label>
          <input class="form-control" formControlName="market" type="text"/>
      </div> -->

            <div class="form-group">
                <label class="control-label">Status</label>
                <select class="form-control" formControlName="status">
                    <option *ngFor="let status of statuses" [ngValue]="status.code">{{status.name}}</option>
                </select>
            </div>



        </div>

        <div class="col-md-6" formGroupName="address">

            <h3 class="form-group pt-3">Address</h3>

            <div class="form-group">
                <label class="control-label">Line 1</label>
                <input class="form-control" formControlName="line_1" type="text" />
            </div>

            <div class="form-group">
                <label class="control-label">Line 2</label>
                <input class="form-control" formControlName="line_2" type="text" />
            </div>

            <div class="form-group">
                <label class="control-label">Line 3</label>
                <input class="form-control" formControlName="line_3" type="text" />
            </div>

            <div class="form-group">
                <label class="control-label">Postal code</label>
                <input class="form-control" formControlName="postcode" type="text" />
            </div>

            <div class="form-group">
                <label class="control-label">Country</label>
                <select class="form-control" formControlName="country">
                    <option *ngFor="let country of countries" [ngValue]="country.code">{{country.name}}</option>
                </select>
            </div>

        </div>



    </form>
</div>