import { DashboardComponent } from './dashboard/dashboard.component';
import { Transition, Ng2StateDeclaration } from '@uirouter/angular'
import { MembrsService, validateResolve } from '@nakedcreativity/membrs-angular-helper'

export const loginResolve = { 
    token: 'checkLogin',
    deps: [MembrsService],
    resolveFn: checkLoginResolve
} 

export const permissionResolve = { 
    token: 'checkPermission',
    deps: [MembrsService, Transition],
    resolveFn: checkPermissionResolve
} 

// core app components
export const dashboardState:Ng2StateDeclaration = {
	name: 'dashboard', 
	url: '/',
	views: {
        'app': {
            component: DashboardComponent
        }
    },
    resolve:[validateResolve]
}



export const distributorsFutureState:Ng2StateDeclaration = {
    name: 'distributors.**',
    url: '/distributors',
    loadChildren: () => import('./distributors/distributors.module').then(m => m.DistributorsModule)
  }


export const warrantyFutureState:Ng2StateDeclaration = {
    name: 'warranty.**',
    url: '/mtg',
    loadChildren: () => import('./warranty/warranty.module').then(m => m.WarrantyModule)
  }


export const userFutureStates:Ng2StateDeclaration = {
    name: 'users.**',
    url: '/users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
  }

export const publicationsFutureStates:Ng2StateDeclaration = {
    name: 'publications.**',
    url: '/publications',
    loadChildren: () => import('./publications/publications.module').then(m => m.PublicationsModule)
  }

export const incidentsFutureStates:Ng2StateDeclaration = {
    name: 'incidents.**',
    url: '/incidents',
    loadChildren: () => import('./incidents/incidents.module').then(m => m.IncidentsModule)
  }

export const ordersFutureStates:Ng2StateDeclaration = {
    name: 'orders.**',
    url: '/orders',
    loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule)
  }


/** The top level state(s) */
export const APP_STATES = [
    dashboardState,
	distributorsFutureState,
	warrantyFutureState,
    userFutureStates,
    publicationsFutureStates,
    incidentsFutureStates,
    //ordersFutureStates
]


export function checkLoginResolve(membrsService) {
    return new Promise((resolve, reject) => {
        
        return membrsService.reissue().then((response)=>{
            //console.log('response from membrs service ', response)
            resolve()
        }).catch(error=>{
            reject(error)
        })

	})
}

export function checkPermissionResolve(membrsService, transition) {
    return new Promise((resolve, reject) => {

        var data = transition.to().data
       
        if(data && typeof data.permission !== 'undefined'){
            if(data.permission < membrsService.profile.permission){
                reject('INSUFFICIENT_PERMISSIONS')
            }else {
                resolve()
            }
        }else {
            // state has no restriction
            resolve()
        }

	})
}