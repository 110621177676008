 import { Component, Input } from '@angular/core';
 import { ConfirmService } from "./confirm.service";

 @Component({
    selector: 'confirm',
    templateUrl: 'confirm.component.html',
    styleUrls: ['confirm.component.scss']
   
})
 export class ConfirmComponent {
    message: any;
    constructor(private confirmService: ConfirmService) { }
   
   ngOnInit() {
    //this function waits for a message from confirm service, it gets 
    //triggered when we call this from any other component
    this.confirmService.getMessage().subscribe(message => {
        this.message = message;
    });
  }
}