
<div class="d-flex flex-row wrapper">
    
    <div class="sidebar bg-dark align-self-stretch d-md-block" [ngClass]="open?'d-block':'d-none'">
        <app-nav class="navbar-dark"></app-nav>
    </div>
    
    <div class="app-ui-view">
        <ui-view name="app" [ngClass]="open?'sidebar-open':''"></ui-view>
    </div>
    
</div>


<app-busy></app-busy>
<confirm></confirm>