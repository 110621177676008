import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'incident-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {

  @Input() id

  constructor() { }

  ngOnInit() {
  }

}
