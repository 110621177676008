import { Component, OnDestroy, OnInit } from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';
import { StateService, Transition, UIRouter } from '@uirouter/angular'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import _ from 'underscore'
import { ToastrService } from 'ngx-toastr';
import { Countries } from '../../data'

// Queries at the end of this file
import { BusyService } from '../../ui'

@Component({
  selector: 'app-distributor-details',
  templateUrl: './distributor-details.component.html',
  styleUrls: ['./distributor-details.component.css']
})

export class DistributorDetailsComponent implements OnInit {

  private _distributor: any[];
  public distributorForm: FormGroup


  constructor(private apollo: Apollo, private transition: Transition, private fb: FormBuilder, private toastr: ToastrService, private busy: BusyService, private stateService: StateService) {

 
  }

  ngOnInit() {

    this.distributorForm = this.fb.group({
      name: ['', Validators.required],
      //market: ['', Validators.required],
      status: [1],
      //api_key: ['', Validators.required],
      contact_email: ['', Validators.required],
      contact_phone: [''],
      contact_name: [''],
      head_office_phone: [''],
      internal_id: [''],
      address: this.fb.group({
        line_1: ['', Validators.required],
        line_2: [''],
        line_3: [''],
        postcode: [''],
        country: ['', Validators.required],
      })

    })

    if(this.transition.params().id){
      this.busy.busy()
      this.apollo.watchQuery({
        query: queryDistributor,
        variables: {
          id: this.transition.params().id
        }
      })
      .valueChanges.subscribe((result:any) => {
        //console.log(result.data)
        this._distributor = result.data && result.data.retrieveDistributor;
        this.distributorForm.patchValue(this._distributor)
        this.busy.finished()
      });
    }

  }

  get distributor(){
    return this._distributor
  }

  submit(){

    this.busy.busy()
    var mutation:any;
    var variables:any = {}

    if(!this.transition.params().id){
      mutation = newDistributor
      variables.input = this.distributorForm.value
    }else {
      console.log(this.distributorForm.value)
      mutation = updateDistributor
      variables.input = this.distributorForm.value
      variables.id = this.transition.params().id
      console.log(variables)
    }

    this.apollo.mutate({
      mutation: mutation,
      variables: variables,
      refetchQueries: ['retrieveDistributors']
    }).subscribe((result:any) => {
      this.toastr.success('Success')
      this.busy.finished()
      this.stateService.go('^')
    },(error) => {
      this.toastr.error(error.message)
      this.busy.finished()
      console.log('there was an error sending the query', error);
    });
  
  }

  get statuses() {
    return [
      { code: 0, name: 'Suspended'},
      { code: 1, name: 'Active'}
    ]
  }

  get countries() {
    return Countries
  }

  get title(){
    if(this.transition.params().id){
      return 'Edit distributor'
    }
    return 'New distributor'
  }
  


}


const queryDistributor = gql`
query distributor($id: ID!){
  retrieveDistributor(id: $id) {
      id
      name
		  status
      #market
      address {
        line_1
        line_2
        line_3
        postcode
        country
      }
		  contact_email
      contact_phone
      contact_name
      head_office_phone
      internal_id
  }
}`

const newDistributor = gql`
mutation createDistributor($input: DistributorInput!){
  createDistributor(input: $input){
    id
    name
    #market
    #status
    address {
      line_1
      line_2
      line_3
      postcode
      country
    }
    contact_email
    contact_phone
    contact_name
    head_office_phone
    internal_id
  }
}
`

const updateDistributor = gql`
mutation updateDistributor($input: DistributorInput!, $id: ID!){
  updateDistributor(input: $input, id: $id){
    id
    name
    #market
    status
    address {
      line_1
      line_2
      line_3
      postcode
      country
    }
    contact_email
    contact_phone
    contact_name
    head_office_phone,
    internal_id
  }
}
`