import { Component, OnDestroy, OnInit, forwardRef, ViewEncapsulation, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms'
import { Uppy } from '@uppy/core';
import { ToastrService } from 'ngx-toastr';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

const DragDrop = require('@uppy/drag-drop')
const ProgressBar = require('@uppy/progress-bar')
const AwsS3 = require('@uppy/aws-s3')

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    }
  ]
})
export class FileUploadComponent implements OnInit {
  
  private uppy: any
  //private UppyFile:any;

  @Input() id;
  @Input() type;

  private _file: any = {}

  constructor(private toastr: ToastrService, private apollo: Apollo) { }

  ngOnInit() {

    this.uppy = new Uppy({debug: true, autoProceed: true})
    var apollo = this.apollo
    var id = this.id
    var type = this.type 

    this.uppy
      .use(DragDrop, {target: '.uppy'})
      .use(AwsS3, {
        getUploadParameters(file){

          console.log('uploading type', type)
          var variables:any = {
            filename: file.name
          }
          if(id)
            variables.id = id

          return new Promise((resolve, reject)=>{
            console.log('inside promise')
            apollo.mutate({
              mutation: UploadFile,
              variables: variables
            }).subscribe((result:any) => {
              
              result.data.uploadFile.fields = []
              result.data.uploadFile.headers = []
              resolve(result.data.uploadFile)
            },(error) => {
              console.log(error)
              reject(error)
            });
      
          })
        }
      })
      .use(ProgressBar, {target: '.uppy-progress', hideAfterFinish: false}) 

    this.uppy.on("complete", result => {
      if(result.successful.length > 0){
        this.toastr.success('Successful upload!');
        
        this.file = {
          name: result.successful[0].name,
          extension: result.successful[0].extension,
          size: result.successful[0].size
        }
      }
      if(result.failed.length > 0){
        this.toastr.error('Failed to upload file');
        console.log(result)
      }

    })
  }

  get file() {
    return this._file;
  }

  set file(val) {
    this._file = val;
    this.propagateChange(this._file);
  }

  writeValue(value: any) {
    this.file = value;
  }
  propagateChange = (_: any) => { };
  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }

  validate(c: FormControl) {
    if (this._file > 0)
      return true
    return false
  }

  getS3SignedUrl(file){
   
    console.log('get url')
    console.log(file)
    return new Promise((resolve, reject)=>{
      
      this.apollo.mutate({
        mutation: UploadFile,
        variables: {
          filename: file.name,
          id: this.id
        }
      }).subscribe((result:any) => {
        this.toastr.success('Successfully deleted category')
        resolve(result.data.uploadFile)
      },(error) => {
        //this.toastr.error(error.message)
        console.log('there was an error sending the query', error);
        reject(error)
      });

    })

  }
}


const UploadFile = gql`
mutation uploadFile($filename: String!, $id: ID){
  uploadFile(filename: $filename, id: $id){
    method
    url
    fields
    headers
  }
}
`