import { registerLocaleData } from "@angular/common";
import { HttpClientModule, HttpHeaders } from "@angular/common/http";
import localeGb from "@angular/common/locales/en-GB";
import {
  LOCALE_ID,
  NgModule,
  NgModuleFactoryLoader,
  SystemJsNgModuleLoader,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ApolloModule, APOLLO_OPTIONS } from "apollo-angular";
import { HttpLink, HttpLinkModule } from "apollo-angular-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import { ToastrModule, ToastrService } from "ngx-toastr";
registerLocaleData(localeGb);

import { IncidentsModule } from "./incidents/incidents.module";

import { JwtModule } from "@auth0/angular-jwt";
import { AppComponent } from "./app.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { NavComponent } from "./nav/nav.component";
import { UiModule } from "./ui";
import { UsersModule } from "./users/users.module";

// router
import { UIRouterModule } from "@uirouter/angular";
import { APP_STATES } from "./app.states";
import { routerConfigFn } from "./router.config";

import { MembrsModule } from "@nakedcreativity/membrs-angular-helper";
import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { SortablejsModule } from "ngx-sortablejs";
import { environment } from "../environments/environment";

export function createApollo(httpLink: HttpLink, toastrService: ToastrService) {
  console.log("create apollo");

  const http = httpLink.create({
    uri: "https://" + environment.graphql + "/graphql",
    headers: new HttpHeaders({ "X-API-KEY": environment.api_key }),
  });

  const errorLink = onError((error) => {
    if (error.networkError) {
      var networkError: any = error.networkError;

      networkError.error.errors.forEach((error) => {
        // console.log(error)
        toastrService.error(error.message);
      });
    }
    if (error.graphQLErrors) {
      error.graphQLErrors.forEach((error) => {
        toastrService.error(error.message);
      });
    }
  });
  // const authMiddleware = new ApolloLink((operation, forward) => {
  //   // add the authorization to the headers
  //   operation.setContext({
  //     headers: new HttpHeaders().set('Authorization', localStorage.getItem('membrs') || null)
  //   });

  //   return forward(operation);
  // });

  return {
    link: ApolloLink.from([
      errorLink,
      //authMiddleware,
      http,
    ]),
    cache: new InMemoryCache(),
  };
}

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    ApolloModule,
    HttpLinkModule,
    UIRouterModule.forRoot({
      states: APP_STATES,
      useHash: false,
      config: routerConfigFn,
    }),
    UiModule.forRoot(),
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    SortablejsModule.forRoot({ animation: 150 }),
    MembrsModule.forRoot({
      api: environment.membrs.api,
      apiProtocol: environment.membrs.apiProtocol,
      graphQL: environment.membrs.graphQL,
      login: environment.membrs.login,
      defaultState: "dashboard",
    }),
    NgbModalModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return localStorage.getItem("membrs");
        },
        allowedDomains: [environment.graphql],
      },
    }),
    UsersModule,
    IncidentsModule,
  ],
  declarations: [AppComponent, DashboardComponent, NavComponent],
  providers: [
    {
      provide: NgModuleFactoryLoader,
      useClass: SystemJsNgModuleLoader,
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, ToastrService],
    },
    {
      provide: LOCALE_ID,
      useValue: "en-GB",
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// const middlewareLink = new ApolloLink((op, forward) => {
//   return forward(op).map(response => {
//     //console.log('Using App');
//     return response;
//   });
// });
