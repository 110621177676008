import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { IncidentsListDataSource } from './incidents-list.datasource';
// import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { MembrsService } from '@nakedcreativity/membrs-angular-helper';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { BusyService } from 'src/app/ui';
import { Variants } from '../../variants';
import { IncidentReportGQL, ListDistributors } from '../incident.graphql';
import { severities, statuses, subsystems } from '../variables';

@Component({
  selector: 'app-incidents-list',
  templateUrl: './incidents-list.component.html',
  styleUrls: ['./incidents-list.component.css'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
})
export class IncidentsListComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) matSort: MatSort;
  dataSource: IncidentsListDataSource;
  filter: FormGroup;

  private _distributors: Array<any> = [];
  private _subscriptions: Array<Subscription> = [];

  constructor(
    private fb: FormBuilder,
    private apollo: Apollo,
    private membrsService: MembrsService,
    private busy: BusyService,
    private toastr: ToastrService
  ) {
    this.filter = this.fb.group({
      distributor: this.fb.control(''),
      status: this.fb.control(''),
      severity: this.fb.control(''),
      sub_system: this.fb.control(''),
      variant: this.fb.control(''),
      start: this.fb.control(''),
      end: this.fb.control(''),
      parts_requested: this.fb.control(''),
      parts_received: this.fb.control(''),
    });
  }

  sortData(sort: Sort) {
    this.dataSource.sort = sort;
  }

  ngOnInit() {
    this._subscriptions.push(
      this.filter.valueChanges.subscribe((value) => {
        this.dataSource.filter = this.formValues;
      })
    );
    this.dataSource = new IncidentsListDataSource(this.apollo, this.busy);

    if (this.admin) {
      this._subscriptions.push(
        this.apollo
          .watchQuery({
            query: ListDistributors,
            errorPolicy: 'all',
          })
          .valueChanges.subscribe((result: any) => {
            this._distributors = result.data && result.data.retrieveDistributors;
          })
      );
    }
  }

  get selectedColumns() {
    return ['id', 'logged_date', 'variant', 'distributor', 'subsystem', 'fracas', 'severity', 'status', 'parts'];
  }

  get admin() {
    if (!this.membrsService) return false;

    return this.membrsService.profile.data_permission == 1;
  }

  get distributors() {
    return this._distributors;
  }

  get formValues() {
    return this.filter.value;
  }

  get today(): NgbDateStruct {
    const today = new Date();
    return {
      year: today.getUTCFullYear(),
      month: today.getUTCMonth(),
      day: today.getDate(),
    };
  }

  export() {
    this.busy.busy();
    this._subscriptions.push(
      this.apollo
        .watchQuery<any>({
          query: IncidentReportGQL,
          variables: this.formValues,
          errorPolicy: 'all',
        })
        .valueChanges.subscribe(
          (result: any) => {
            this.busy.finished();
            try {
              window.location.href = result.data.retrieveProductIncidentReport.url;
            } catch (error) {
              this.toastr.error(error.message);
            }
          },
          (error) => {
            this.toastr.error(error.message);
            this.busy.finished();
          }
        )
    );
  }

  get statuses() {
    return statuses;
  }

  get severities() {
    return severities;
  }

  get variants() {
    return Variants;
  }

  get subsystems() {
    return subsystems;
  }

  ngOnDestroy() {
    this._subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
