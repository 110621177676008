import { Ng2StateDeclaration } from '@uirouter/angular';
import { IncidentsListComponent, IncidentDetailsComponent, NewIncidentComponent } from './'
import { loginResolve, permissionResolve } from '../app.states'

export const incidentListState:Ng2StateDeclaration = {
	name: 'incidents', 
    url: '/pirs',
    views: {
        'app@':{
            component: IncidentsListComponent
        }
    },
    resolve: [
        loginResolve
    ]
}

export const newIncidentState:Ng2StateDeclaration = {
	name: 'incidents.new', 
    url: '/new?generator',
    views: {
        'app@': {
            component: NewIncidentComponent
        }
    }
}
export const incidentDetailState:Ng2StateDeclaration = {
	name: 'incidents.detail', 
    url: '/:id',
    views:  {
        'app@': {
            component: IncidentDetailsComponent
        }
    }

}

export const editIncidentState:Ng2StateDeclaration = {
	name: 'incidents.detail.edit', 
	url: '/edit',
    views: {
        'app@': {
            component: NewIncidentComponent
        }
    }
}


/** The top level state(s) */
export const STATES = [
    incidentListState,
    newIncidentState,
    incidentDetailState,
    editIncidentState
]