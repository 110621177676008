export const severities = [
    'Repair Required',
    'Safety',
    'Unit Beyond Repair',
    'Other Issue'
]
export const statuses = [
    'Approved',
    'Rejected',
    'Under Review',
    'New (Fracas)',
    'Monitor (FRACAS)',
    'Problem required (FRACAS)',
    'Problem raised (FRACAS)',
    'Problem not required (FRACAS)',
    'Problem closed (FRACAS)'
]

export const workpackages = [
    'Turbo Alternator',
    'Fuel System',
    'Recuperator',
    'Combustor',
    'Injector',
    'Igniter',
    'Power Electronics',
    'Control Electronics',
    'Electrical Distribution System',
    'Software',
    'Enclosure',
    'Aerothermals',
    'System'
  ]

export const subsystems = [
    'AFE',
    'Air Box',
    'Anti Vibration',
    'Atomising Air Compressor',
    'Battery Isolator and Contactor',
    'Batteries/Tray/Wiring',
    'Brake Resistor',
    'Cabinet',
    'Combustor',
    'Combustor Insulation',
    'Cooling Control Unit',
    'Customer Connection',
    'DC/DC High Voltage',
    'Documentation',
    'Ducting and Diffuser',
    'Electrical Controls',
    'Electrical Distribution System',
    'Electrical Enclosure',
    'Electrical Systems',
    'Emergency Stop',
    'Enclosure Alarms',
    'Exhaust',
    'Fans',
    'Filters',
    'Filtration System',
    'Flow Meter',
    'Fuel Filter',
    'Fuel Pump',
    'Fuel System',
    'Gateway',
    'Heat Exchanger',
    'Heatsinks',
    'HMI',
    'HV Harnessing',
    'Ignition Detection',
    'Ignition System',
    'Injector',
    'Insulation',
    'Lifting Structure',
    'Locking',
    'LV Harnessing',
    'Main Enclosure',
    'MCU',
    'MCU Enclosure',
    'Motor Generator (Alternator)',
    'Panels',
    'Pipework',
    'Plinth',
    'Power Electronics',
    'Power Electronics Enclosure',
    'Power Plant',
    'Sealing',
    'Sensors and Actuators',
    'Signal Harnessing',
    'Software',
    'Software Code File',
    'Software EEPROM File',
    'Software FPGA Code File',
    'Structure',
    'Thermal Insulation',
    'Turbine / Compressor',
    'Turbo Machine',
    'Valves',
    'Ventilation',
    'VMI',
    'Wastegate'
  ]