<app-title title="Product Incident Reports" [template]="actions"></app-title>
<ng-template #actions>
    <div class="ml-0 ml-md-2 action-group">	
      <button class="btn btn-outline-secondary mr-2" (click)="export()" *ngIf="admin">Export</button>
      <a class="btn btn-primary" uiSref=".new">New Product Incident Report</a>
    </div>
</ng-template>

<form class="form-inline p-3" [formGroup]="filter" *ngIf="admin">

  <div class="mb-3">
    <label class="mb-1">Distributor</label>
    <select class="form-control mr-4" formControlName="distributor">
      <option ngValue="">All</option>
      <option [ngValue]="distributor.id" *ngFor="let distributor of distributors">{{distributor.name}}</option>
    </select>
  </div>

  <div class="mb-3">
    <label class="mb-1">Status</label>
    <select class="form-control mr-4" formControlName="status">
      <option ngValue="">All</option>
      <option [ngValue]="status" *ngFor="let status of statuses">{{status}}</option> 

      <!-- rejected, approved, under review, New (FRACAS),  -->
    </select>
  </div>
    
  <div class="mb-3">
    <label class="mb-1">Severity</label>
    <select class="form-control mr-4" formControlName="severity">
      <option ngValue="">All</option>
      <option [ngValue]="severity" *ngFor="let severity of severities">{{ severity }}</option>
    </select>
  </div>

  <div class="mb-3">
    <label class="mb-1">Variant</label>
    <select class="form-control mr-4" formControlName="variant">
      <option ngValue="">All</option>
      <option [ngValue]="variant" *ngFor="let variant of variants">{{ variant }}</option>
    </select>
  </div>

  <div class="mb-3">
    <label class="mb-1">Subsystem</label>
    <select class="form-control mr-4" formControlName="sub_system">
      <option ngValue="">All</option>
      <option *ngFor="let subsystem of subsystems" [ngValue]="subsystem">{{ subsystem }}</option>
    </select>
  </div>

  <div class="mb-3">
    <label class="mb-1">Parts</label>
    <input type="checkbox" formControlName="parts_requested" class="mr-2">Requested<input type="checkbox" formControlName="parts_received" class="mx-2">Received
  </div>

  <div class="break"></div>

  <div class="mb-3">    
    <label class="mb-1">Start date</label>
    <div class="input-group mr-4">
      <input class="form-control" placeholder="yyyy-mm-dd" name="start" formControlName="start" ngbDatepicker #s="ngbDatepicker" [maxDate]="today">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary icon-calendar" (click)="s.toggle()" type="button"></button>
      </div>
    </div>
  </div>
    
  <div class="mb-3">
    <label class="mb-1">End date</label>
    <div class="input-group mr-4">
      <input class="form-control" placeholder="yyyy-mm-dd" name="end" formControlName="end" ngbDatepicker #e="ngbDatepicker" [maxDate]="today">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary icon-calendar" (click)="e.toggle()" type="button"></button>
      </div>
    </div>
  </div>

  

</form>

<table class="table table-striped table-bordered table-hover" cdk-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
  
  <!-- Column Definition -->
  <ng-container cdkColumnDef="id">
    <th cdk-header-cell *cdkHeaderCellDef mat-sort-header>PIR Number</th>
    <td cdk-cell *cdkCellDef="let row">{{row.system_id}}</td>
  </ng-container>

  <ng-container cdkColumnDef="logged_date">
    <th cdk-header-cell *cdkHeaderCellDef mat-sort-header>PIR date</th>
    <td cdk-cell *cdkCellDef="let row">{{ row.incident_date | date : 'mediumDate' }}</td>
  </ng-container>

  <ng-container cdkColumnDef="distributor">
    <th cdk-header-cell *cdkHeaderCellDef mat-sort-header>Distributor</th>
    <td cdk-cell *cdkCellDef="let row">{{row.distributor?.name}}</td>
  </ng-container>

  <ng-container cdkColumnDef="variant">
    <th cdk-header-cell *cdkHeaderCellDef mat-sort-header>Variant</th>
    <td cdk-cell *cdkCellDef="let row">{{row.generator?.variant}}</td>
  </ng-container>

  <ng-container cdkColumnDef="fracas">
    <th cdk-header-cell *cdkHeaderCellDef mat-sort-header>FRACAS ID</th>
    <td cdk-cell *cdkCellDef="let row">{{row.relyence_id}}</td>
  </ng-container>

  <ng-container cdkColumnDef="subsystem">
    <th cdk-header-cell *cdkHeaderCellDef mat-sort-header>Subsystem</th>
    <td cdk-cell *cdkCellDef="let row">{{row.sub_system}}</td>
  </ng-container>

  <ng-container cdkColumnDef="severity">
    <th cdk-header-cell *cdkHeaderCellDef mat-sort-header>Severity</th>
    <td cdk-cell *cdkCellDef="let row">{{row.severity}}</td>
  </ng-container>

  <ng-container cdkColumnDef="parts">
    <th cdk-header-cell *cdkHeaderCellDef mat-sort-header>Parts</th>
    <td cdk-cell *cdkCellDef="let row"><span class="badge badge-primary" *ngIf="row.parts_requested">Requested</span> <span class="badge badge-secondary" *ngIf="row.parts_received">Received</span></td>
  </ng-container>


  <ng-container cdkColumnDef="status">
    <th cdk-header-cell *cdkHeaderCellDef mat-sort-header>Status</th>
    <td cdk-cell *cdkCellDef="let row">{{row.status}}</td>
  </ng-container>


  <!-- Header and Row Declarations -->
  <tr cdk-header-row *cdkHeaderRowDef="selectedColumns"></tr>
  <tr cdk-row *cdkRowDef="let row; columns: selectedColumns" uiSref="incidents.detail" [uiParams]="{id:row.id}"></tr>

</table>