import { UIRouter } from "@uirouter/core";
import { environment } from "../environments/environment";

export function routerConfigFn(router: UIRouter) {
  const transitionService = router.transitionService;
  const stateService = router.stateService;

  stateService.defaultErrorHandler(function (error) {
    if (error.detail == "NOT_LOGGED_IN")
      window.location.href = environment.membrs.login;
    //console.log(MembrsSettings)

    if (error.detail == "INSUFFICIENT_PERMISSIONS")
      stateService.go("dashboard");
  });
}
