import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserListComponent } from './user-list/user-list.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserProfileComponent } from './user-profile/user-profile.component';

import { STATES } from './users.states'
import { UIRouterModule } from '@uirouter/angular'
import { ReactiveFormsModule } from '@angular/forms'
import { UiModule } from '../ui'

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({states: STATES}),
    ReactiveFormsModule,
    UiModule
  ],
  declarations: [UserListComponent, UserDetailComponent, UserProfileComponent],
  exports: [UserProfileComponent],
  entryComponents: [UserProfileComponent]
})
export class UsersModule { 

  // static forRoot(): ModuleWithProviders {

  //   return {
  //     ngModule: UiModule,
  //     providers: [
  //       UserService
  //     ]
  //   }
  // }

}
