<app-title [title]="title" [template]="actions" *ngIf="incident"></app-title>
<ng-template #actions>
    <div class="ml-0 ml-md-2 action-group">	
        <a *ngIf="!incident?.relyence_id" class="btn btn-primary mr-2" uiSref=".edit">Edit</a>
        <a class="btn btn-outline-secondary" uiSref=".^">Back</a>
    </div>
</ng-template>

<div class="col my-3" *ngIf="incident">

    <div class="row">
        
        <div class="col-md-6">
            <div class="row">
                <h3 class="col">PIR Submission</h3>
                <div class="col-auto"><span class="badge badge-secondary">{{ incident.status }}</span></div>
            </div>
            <div class="card mb-3">
                <h4 class="card-header">Generator</h4>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item d-flex px-0"><span class="col">Generator</span><a class="col-auto" uiSref="warranty.edit" [uiParams]="{id: incident.generator?.id}">{{ incident.generator?.generator_serial }}</a></li>
                    <li class="list-group-item d-flex px-0"><span class="col">Variant</span><span class="col-auto">{{ incident.generator?.variant }}</span></li>
                    <li class="list-group-item d-flex px-0"><span class="col">Installed date</span><span class="col-auto">{{ incident.generator?.installation_date | date: 'd LLLL yyyy' }}</span></li>
                    <li class="list-group-item d-flex px-0"><span class="col">Customer</span><span class="col-auto">{{ incident.customer.name }} {{ incident.customer.account }}</span></li>
                    
                </ul>
            </div>

            
            <div class="card mb-3">
                <h4 class="card-header">Details</h4>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item d-flex px-0"><span class="col">Incident date</span><span class="col-auto">{{ incident.incident_date | date: 'd LLLL yyyy' }}</span></li>
                    <li class="list-group-item d-flex px-0" *ngIf="admin && incident.relyence_id"><span class="col">FRACAS</span><span class="col-auto">{{ incident.relyence_id }}</span></li>
                    <li class="list-group-item d-flex px-0"><span class="col">Severity</span><span class="col-auto"><span class="badge badge-danger">{{ incident.severity }}</span></span></li>
                    <li class="list-group-item d-flex px-0"><span class="col">Workpackage</span><span class="col-auto">{{ incident.workpackage }}</span></li>
                    <li class="list-group-item d-flex px-0"><span class="col">Sub-system</span><span class="col-auto">{{ incident.sub_system }}</span></li>
                    <li class="list-group-item d-flex px-0"><span class="col">Operating conditions</span><span class="col-auto">{{ incident.operating_conditions }}</span></li>
                    <li class="list-group-item d-flex px-0"><span class="col">Run cycles</span><span class="col-auto">{{ incident.run_cycles }}</span></li>
                    <li class="list-group-item d-flex px-0"><span class="col">Run hours</span><span class="col-auto">{{ incident.run_hours }}</span></li>
                    
                </ul>
            </div>

            <div class="card mb-3">
                <h4 class="card-header">Incident Description</h4>
                <div class="card-body">{{ incident.incident_description }}</div>
            </div>

            <div class="card mb-3">
                <h4 class="card-header">Troubleshooting Efforts</h4>
                <div class="card-body">{{ incident.troubleshooting_efforts }}</div>
            </div>

            <div class="card mb-3">
                <h4 class="card-header">Corrective Action</h4>
                <div class="card-body">{{ incident.corrective_action }}</div>
            </div>

            <div class="card mb-3">
                <h4 class="card-header">Parts</h4>
                <div class="card-body">{{ incident.parts }} <span *ngIf="!parts">None</span></div>
                <div class="card-footer">
                    <div>
                        <p class="mb-2" *ngIf="incident.parts_requested"><strong>Parts requested:</strong> {{ incident.parts_requested | date : 'd LLLL yyyy' }}</p>
                        <p class="mb-2" *ngIf="incident.parts_received"><strong>Parts received:</strong> {{ incident.parts_received | date : 'd LLLL yyyy' }}</p>
                    </div>
                    <div>
                        <button class="mr-3" *ngIf="admin && !incident.parts_requested" class="btn btn-primary" (click)="partsRequested()">Parts requested</button>
                        <button *ngIf="admin && incident.parts_requested && !incident.parts_received" class="btn btn-primary" (click)="partsReceived()">Parts received</button>
                    </div>
                </div>
            </div>
          

            <div class="card mb-3">
                <h4 class="card-header">Attachments</h4>
                <ul class="list-group list-group-flush" *ngIf="attachments.length > 0">
                    <li class="list-group-item d-flex px-0 align-items-center list-group-item-action" *ngFor="let attachment of attachments" (click)="download($event, attachment.url)"><span class="ml-3 file-icon file-icon-{{attachment.extension}}"></span><span class="col">{{ attachment.name }}</span><span class="col-auto">{{ size(attachment.size) }}</span></li>
                </ul>
                <p class="card-body"  
                *ngIf="attachments.length == 0">No attachments</p>
            </div>

            <div class="card mb-3">
                <h4 class="card-header">Photos</h4>
                <div class="card-body">
                    <figure class="figure file mr-3" *ngFor="let attachment of photos" (click)="download($event, attachment.url)"> 
                        <!-- <img class="mb-3" [src]="attachment.thumbnail" [alt]="attachment.name"/> -->
                        <figcaption class="figure-caption d-flex align-items-center">
                            <span class="file-icon file-icon-{{attachment.extension}}"></span>
                            <div class="col">
                                {{ attachment.name }}
                                <p class="mb-0">{{ size(attachment.size) }}</p>
                            </div>
                        </figcaption>
                    </figure>
                    <p *ngIf="photos.length == 0">No photos</p>
                </div>
            </div>
          

        </div>
        
        <div class="col-md-6">
            <h3>History</h3>

            <div *ngFor="let comment of comments" class="card mb-3" [class.bg-private]="comment.private">
                <div class="card-body">
                    <b>{{ comment.author.firstname }} {{ comment.author.surname }}</b><br/>
                    {{ comment.text }}
                    <br/><span class="text-muted">{{ comment.timestamp | date : 'd LLLL yyyy, HH:mm' }} {{comment.id}}<span *ngIf="comment.private" class="badge badge-secondary">Private</span></span>
                </div>
            </div>

            <form class="form card" [formGroup]="commentForm">
                <div class="card-body">

                    <div class="form-group">
                        <label class="control-label">Comment</label>
                        <textarea class="form-control" formControlName="text" cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="3"></textarea>
                    </div>


                    <div class="form-check" *ngIf="admin">
                        <input class="form-check-input" type="checkbox" formControlName="private" id="private">
                        <label class="form-check-label" for="private">Private comment</label>
                    </div>

                   
                
                    
                </div>
                <div class="card-footer">
                    <button class="btn btn-primary mr-2" *ngIf="incident.relyence_id || !admin" (click)="comment()" [disabled]="commentForm.invalid">Submit</button>
                    <button class="btn btn-success mr-2" *ngIf="!incident.relyence_id && admin" (click)="approve(true)">Approve</button>
                    <button class="btn btn-danger" *ngIf="!incident.relyence_id && admin" (click)="approve(false)" [disabled]="commentForm.invalid">Reject</button>
                </div>
            </form>
        </div>
        
    </div>
    
</div>