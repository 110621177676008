import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'arrayFilter'
})
export class ArrayFilterPipe implements PipeTransform {
    transform(items: any[], filter: any): any {

        console.log(items)
        console.log(filter)
        if (!items || !filter) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        return items.filter((item:any) => item.type.indexOf(filter.type) !== -1);
    }
}