import { Component, OnInit } from '@angular/core';
import { BusyService } from './busy.service'
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';

@Component({
  selector: 'app-busy',
  templateUrl: './busy.component.html',
  styleUrls: ['./busy.component.scss'],
  animations: [
    trigger('showHide', [
      state('show', style({
        opacity: 1,
        display: 'block'
      })),
      state('hide', style({
        opacity: 0,
        display: 'none'
      })),
      transition('show => hide', [
        animate('0.25s')
      ]),
      transition('hide => show', [
        animate('0.25s')
      ])
    ])
  ]
})
export class BusyComponent implements OnInit {

  constructor(private busyService: BusyService) {

    
  }

  ngOnInit() {
  }

  get show(){
    return this.busyService.show
  }

  

}
