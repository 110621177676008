import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { NewIncidentComponent } from './new-incident/new-incident.component';
import { IncidentsListComponent } from './incidents-list/incidents-list.component';
import { IncidentDetailsComponent } from './incident-details/incident-details.component';
import { IncidentsService } from './incidents.service'
import { STATES } from './incidents.states'
import { UIRouterModule } from '@uirouter/angular'
import { UiModule } from '../ui'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap'
import { NewIncidentComponent } from './new-incident/new-incident.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatSortModule } from '@angular/material/sort';
import { CdkTableModule } from '@angular/cdk/table'

@NgModule({
  imports: [
    CommonModule,
    UiModule,
    UIRouterModule.forChild({states: STATES}),
    ReactiveFormsModule,
    FormsModule,
    NgbDatepickerModule,
    TextFieldModule,
    MatSortModule,
    CdkTableModule
  ],
  declarations: [
    IncidentsListComponent, 
    IncidentDetailsComponent,
    NewIncidentComponent,
    ConfirmationComponent
  ],
  providers: [ IncidentsService ]
})
export class IncidentsModule { }
