<app-title [title]="title" [template]="actions"></app-title>

<ng-template #actions>
    <div class="ml-0 ml-md-2 action-group">	
        <button class="btn btn-primary mr-2" (click)="submit()" [disabled]="userForm.invalid">Submit</button>
        <a class="btn btn-outline-secondary" uiSref=".^">Cancel</a>
    </div>
</ng-template>


<div class="col">

  <form class="form row" [formGroup]="userForm" autocomplete="off">

    <div class="col-md-6">
      <h3 class="form-group pt-3">User details</h3>

      <div class="form-group">
        <label class="control-label">First name</label>
        <input class="form-control" formControlName="firstname" type="text" />
      </div>

      <div class="form-group">
        <label class="control-label">Surname</label>
        <input class="form-control" formControlName="surname" type="text" />
      </div>

      <div class="form-group" *ngIf="admin">
        <label class="control-label">Distributor</label>
        <select class="form-control" formControlName="distributor" [compareWith]="distributorCompare">
          <option *ngFor="let distributor of distributors; index as i" [ngValue]="distributor">{{ distributor.name }}</option>
        </select>
      </div>

      <div class="form-group">
        <label class="control-label">Email</label>
        <input class="form-control" formControlName="email" type="text" />
      </div>

      <div class="form-group">
        <label class="control-label">Permissions</label>
        <select class="form-control" formControlName="permission">
          <option *ngFor="let permission of permissions; index as i" [value]="permission.id">{{ permission.name }}</option>
        </select>
      </div>

      <div class="form-group" *ngIf="edit">
        <label class="control-label">Status</label>
        <select class="form-control" formControlName="status">
          <option *ngFor="let status of statuses; index as i" [value]="i">{{ status.name }}</option>
        </select>
      </div>
      
    </div>


    

  </form>
</div>
