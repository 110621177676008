<app-title title="User" [template]="actions"></app-title>
<ng-template #actions>
    <div class="ml-0 ml-md-2 action-group">
        <a class="btn btn-primary" uiSref=".new">New User</a>
    </div>
</ng-template>

<table class="table table-striped">
    <thead>
        <tr class="sortable">
            <th (click)="sort('firstname')">First Name <span [class]="sortClass('firstname')"></span></th>
            <th (click)="sort('surname')">Last Name <span [class]="sortClass('surname')"></span></th>
            <th (click)="sort('distributor.name')">Distributor <span [class]="sortClass('distributor.name')"></span></th>
            <th (click)="sort('status')">Status <span [class]="sortClass('status')"></span></th>
            <th (click)="sort('created')">Created <span [class]="sortClass('created')"></span></th>
            <th (click)="sort('updated')">Updated <span [class]="sortClass('updated')"></span></th>
            <th (click)="sort('permission')">Permission <span [class]="sortClass('permission')"></span></th>
            <th *ngIf="admin"></th>
        </tr>
    </thead>

    <tbody>
        <tr *ngFor="let user of users">
            <td>{{ user.firstname }}</td>
            <td>{{ user.surname }}</td>
            <td>{{ user.distributor.name }}</td>
            <td>{{ statuses[user.status]?.name }}</td>
            <td>{{ user.updated | date : 'd LLLL yyyy'}}</td>
            <td>{{ user.created | date : 'd LLLL yyyy'}}</td>
            <td>{{ permissions[user.permission]?.name }} </td>
            <td class="text-right actions" *ngIf="admin">
                <a class="btn btn-primary mr-2" uiSref=".details" [uiParams]="{id: user.id}">Edit</a>
                <button class="btn btn-outline-danger" (click)="delete(user)">Delete</button>
            </td>
        </tr>
    </tbody>
</table>
<!-- <ul class="list-group" *ngIf="distributors">
      <li class="list-group-item" *ngFor="let distributor of distributors">
        <p><a uiSref=".detail" [uiParams]="{id: distributor.id}">{{distributor.id}}: {{distributor.name}}</a></p>
      </li>
    </ul> -->