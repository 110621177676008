import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms'
import _ from 'underscore'
import { ToastrService } from 'ngx-toastr';
import { MembrsService } from '@nakedcreativity/membrs-angular-helper'
import { BusyService } from '../../ui'

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal, private apollo: Apollo, private fb: FormBuilder, private toastr: ToastrService, private membrsService: MembrsService, private busy: BusyService) { }

  profileForm: FormGroup

  ngOnInit() {

    this.profileForm = this.fb.group({
      firstname: ['', Validators.required],
      surname: ['', Validators.required],
      email: ['', Validators.required]
    })

    this.profileForm.patchValue(this.membrsService.profile)

  }

  submit() {
    
    this.busy.busy()

    this.apollo.mutate({
      mutation: updateProfile,
      variables: {
        input: this.profileForm.value
      }
    }).subscribe((result:any) => {
      this.toastr.success('Success')
      this.membrsService.reissue()
      this.busy.finished()
      this.activeModal.close()
    }, (error) => {
      this.toastr.error(error.message)
      console.log('there was an error sending the query', error);
      this.busy.finished()
      //this.activeModal.dismiss()
    });

  }
  cancel(){
    this.activeModal.dismiss()
  }

}

const updateProfile = gql`
mutation updateProfile($input: UserInput){
  updateProfile(input: $input){
    firstname
    surname
    email
  }
}
`

