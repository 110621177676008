import { Component, OnInit } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { Transition } from '@uirouter/angular';
import { BusyService } from 'src/app/ui';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MembrsService } from '@nakedcreativity/membrs-angular-helper';
const filesize = require('filesize')
import { RetrieveIncident, approveIncident, createComment, partsReceived, partsRequested } from '../incident.graphql'
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-incident-details',
  templateUrl: './incident-details.component.html',
  styleUrls: ['./incident-details.component.scss']
})
export class IncidentDetailsComponent implements OnInit {

  constructor(private apollo: Apollo, private transition: Transition, private busy: BusyService, private fb: FormBuilder, private toastr: ToastrService, private membrs: MembrsService) { }

  private _incident:any 
  private _subscriptions:Array<Subscription> = []
  public commentForm: FormGroup

  ngOnInit() {

    this.busy.busy()

    this._subscriptions.push(this.apollo.watchQuery({
      query: RetrieveIncident,
      errorPolicy: 'all',
      variables: {
        id: this.transition.params().id
      }
    })
    .valueChanges.subscribe((result:any) => {
      //console.log(result.data)
      this._incident = result.data && result.data.retrieveProductIncident;

      
      
      //this._incident.relyence = 'FRACAS116'

      this.busy.finished()
    }, error =>{
      console.log(error)
      this.busy.finished()
    }))

    this.commentForm = this.fb.group({
      text: this.fb.control('', Validators.required),
      private: this.fb.control(false)
    })

  }

  get incident(){
    return this._incident
  }

  get admin(){
    return this.membrs.profile.data_permission == 1
  }

  get comments(){
    return this.incident.comments
  }

  get title(){
    return this.incident.system_id + ' – '+this.incident.distributor.name
  }

  get attachments(){
    return this.incident.attachments
  }

  get photos(){
    return this.incident.photos
  }

  get parts(){
    return this.incident.parts
  }

  size(value){
    return filesize(value)
  }

  partsRequested(){
    //this.incident.parts_requested = Date.now()
    
    this._subscriptions.push(this.apollo.mutate({
      mutation: partsRequested,
      variables: {
        id: this.incident.id
      }
    }).subscribe((result:any) => {
      this.busy.finished()
      try {
        this._incident.parts_requested = result.data.partsRequested.parts_requested
      }catch(error){
        this.toastr.error(error.message)
      }
    },(error) => {
      this.toastr.error(error.message)
      this.busy.finished()
    }))

  }

  partsReceived(){
    //this.incident.parts_received = Date.now()
    this._subscriptions.push(this.apollo.mutate({
      mutation: partsReceived,
      variables: {
        id: this.incident.id
      }
    }).subscribe((result:any) => {
      this.busy.finished()
      try {
        this._incident.parts_received = result.data.partsReceived.parts_received
      }catch(error){
        this.toastr.error(error.message)
      }
    },(error) => {
      this.toastr.error(error.message)
      this.busy.finished()
    }))

  }

  download($event, url){
    $event.preventDefault()
    window.open(url)

    // this._subscriptions.push(this.apollo.watchQuery({
    //   query: retrieveUpload,
    //   variables: {
    //     id: this.transition.params().id,
    //     filename: filename
    //   }
    // })
    // .valueChanges.subscribe((result:any) => {
    //   window.open(result.data.retrieveUpload.url)
    //   this.toastr.info('Downloading file in a new window...')
    // }, (error)=>{
    //   this.toastr.error(error.message)
    //   console.log(error)
    // }))

  }

  approve(approve:Boolean){
    this.busy.busy()
    this._subscriptions.push(this.apollo.mutate({
      mutation: approveIncident,
      variables: {
        id: this.incident.id,
        approve: approve,
        comment: this.commentForm.value.text
      }
    }).subscribe((result:any) => {
      this.toastr.success('Success')
      this.busy.finished()
      this.commentForm.reset()
    },(error) => {
      this.toastr.error(error.message)
      this.busy.finished()
    }))
  }


  comment(){
    this.busy.busy()
    this._subscriptions.push(this.apollo.mutate({
      mutation: createComment,
      variables: {
        input: this.commentForm.value,
        id: this.incident.id
      }
    }).subscribe((result:any) => {
      
      this.busy.finished()
      if(!result.data.createComment){
        this.toastr.error('Unknown error posting comment')
      }else {
        this.toastr.success('Success')
        this.incident.comments.push(result.data.createComment)
        this.commentForm.reset()
      }
    },(error) => {
      this.toastr.error(error.message)
      this.busy.finished()
    }))
  }


  ngOnDestroy(){
    this._subscriptions.forEach(subscription=>{
      subscription.unsubscribe()
    })
  }
}

