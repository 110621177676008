import gql from 'graphql-tag';

export const createProductIncident = gql`
mutation createProductIncident($input: ProductIncidentInput!){
  createProductIncident(input: $input) {
    id
    system_id
  }
}
`

export const updateProductIncident = gql`
mutation updateProductIncident($id: ID!, $input: ProductIncidentInput!){
  updateProductIncident(id: $id, input: $input) {
    id
    system_id
    customer {
      name
      account
    }
    relyence_id
    distributor {
      id
      name
    }
    generator {
      generator_lat
      generator_long
      generator_serial
      variant
      installation_date
      id
    }
    #existing_pir
    reference
    incident_date
    workpackage
    troubleshooting_efforts
    incident_description
    corrective_action
    attachments {
      url
      id
      name
      extension
      size
    }
    photos {
      url
      id
      name
      extension
      size
    }
    operating_conditions
    run_cycles
    run_hours
    parts
    comments {
      author {
        surname
        firstname
        admin
      }
      id
      text
      timestamp
      private
    }
    severity
    sub_system
    status
    incident_opened
    incident_closed
    parts_received
    parts_requested
  }
}
`

export const ListWarranties = gql`
query retrieveWarranties{
  retrieveWarranties {
    distributor {
      id
      name
    }
    generator_serial
    id
    generator_lat
    generator_long
    installation_date
  }
}
`


export const RetrieveIncident = gql`

query($id: ID!){
  retrieveProductIncident(id: $id){
    id
    system_id
    customer {
      name
      account
    }
    relyence_id
    distributor {
      id
      name
    }
    generator {
      generator_lat
      generator_long
      generator_serial
      variant
      installation_date
      id
    }
    #existing_pir
    reference
    incident_date
    workpackage
    troubleshooting_efforts
    incident_description
    corrective_action
    attachments {
      url
      id
      name
      extension
      size
    }
    photos {
      url
      id
      name
      extension
      size
    }
    operating_conditions
    run_cycles
    run_hours
    parts 
    comments {
      author {
        surname
        firstname
        admin
      }
      id
      text
      timestamp
      private
    }
    severity
    sub_system
    status
    incident_opened
    incident_closed
    parts_requested
    parts_received
  }
}
`

export const createComment = gql`
mutation createComment($input: CommentInput!, $id: ID!){
  createComment(input: $input, id: $id) {
    id
    text
    author {
      id
      firstname
      surname
      admin
    }
    timestamp
    private
    
  }
}
`



export const retrieveUpload = gql`
query upload($id: ID!, $filename: String!){
  retrieveUpload(id: $id, filename: $filename) {
    method
    url
    fields
    headers
	}
}`


export const approveIncident = gql`
mutation approveProductIncident($id: ID!, $approve: Boolean!, $comment: String){
  approveProductIncident(id: $id, approve: $approve, comment: $comment) {
    id
    system_id
    status
    relyence_id
    comments {
      author {
        surname
        firstname
        admin
      }
      id
      text
      timestamp
      private
    }
  }
}
`


export const ListDistributors = gql`
query retrieveDistributors{
  retrieveDistributors {
      id
      name
  }
}
`

export const IncidentReportGQL = gql`
query($distributor: ID, $status: String, $severity: String, $sort: SortInput, $start: String, $end: String){
  retrieveProductIncidentReport(distributor: $distributor, status: $status, severity: $severity, sort: $sort, start: $start, end: $end){
    url
  }
}
`

export const ListIncidents = gql`
query($distributor: ID, $status: String, $severity: String, $sort: SortInput){
  retrieveProductIncidents(distributor: $distributor, status: $status, severity: $severity, sort: $sort){
    id
    system_id
    customer {
      name
    }
    distributor {
      id
      name
    }
    generator {
      id
      generator_serial
      installation_date
      variant
    }
    #existing_pir
    reference
    relyence_id
    incident_date
    workpackage
    run_cycles
    run_hours
    severity
    sub_system
    status
    incident_opened
    incident_closed
    parts_requested
    parts_received
  }
}
`


export const partsRequested = gql`
mutation partsRequested($id: ID!){
  partsRequested(id: $id) {
    id
    parts_requested
    parts_received
    comments {
      author {
        surname
        firstname
        admin
      }
      id
      text
      timestamp
      private
    }
  }
}
`

export const partsReceived = gql`
mutation partsReceived($id: ID!){
  partsReceived(id: $id) {
    id
    parts_requested
    parts_received
    comments {
      author {
        surname
        firstname
        admin
      }
      id
      text
      timestamp
      private
    }
  }
}
`