import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { BehaviorSubject } from 'rxjs'

const ListDistributors = gql`
query retrieveDistributors{
  retrieveDistributors {
      id
      name
      status
      address {
        country
      }
      created
  }
}
`

@Injectable({
  providedIn: 'root'
})
export class DistributorsService {
  private _distributors: any[];
  private _distributorsObservable: BehaviorSubject<any> = new BehaviorSubject({})

  constructor(private apollo: Apollo) {

    console.log('distributor constructor')
    
    this.apollo
      .watchQuery({
        query: ListDistributors,
        errorPolicy: 'all'
      })
      .valueChanges.subscribe((result: any) => {
        console.log(result)
        this._distributors = result.data && result.data.retrieveDistributors;
        this._distributorsObservable.next(this._distributors)
      });

  }

  get distributors() {
    return this._distributors
  }

  get observable(){
    return this._distributorsObservable
  }
  
}
