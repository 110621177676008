<app-title title="Product Incident Report" [template]="actions"></app-title>
<ng-template #actions>
    <div class="ml-0 ml-md-2 action-group" *ngIf="!submittedIncidentId">
        <button class="btn btn-primary mr-2" (click)="submit()" *ngIf="generators">Submit</button>
        <a class="btn btn-outline-secondary" uiSref=".^" [uiOptions]="{inherit:true, reload: true}">Cancel</a>
    </div>
    <div class="ml-0 ml-md-2 action-group" *ngIf="submittedIncidentId">
        <a class="btn btn-outline-secondary" uiSref=".^" [uiOptions]="{inherit:true, reload: true}">Back</a>
    </div>
</ng-template>

<!--[disabled]="incidentForm.invalid"-->

<incident-confirmation *ngIf="submittedIncidentId" [id]="submittedIncidentId"></incident-confirmation>

<div class="col" *ngIf="!submittedIncidentId">

    <div class="my-3" *ngIf="!generators">
        <a class="btn btn-outline-secondary" uiSref="warranty.registration">Register a generator first</a>
    </div>

    <form class="form" [formGroup]="incidentForm" autocomplete="off" *ngIf="generators">
        <!--*ngIf="generators"-->

        <div class="row">

            <div class="col-sm-12 col-xl-4">

                <h3 class="form-group pt-3">Generator</h3>
                <div class="form-group">
                    <label class="control-label">Select generator <small class="text-muted">Required</small></label>
                    <select class="form-control" [(ngModel)]="generator" [ngModelOptions]="{standalone: true}" (ngModelChange)="generatorSelectChange($event)" [compareWith]="compareFn">
                        <option disabled>Select generator</option>
                        <option *ngFor="let generator of generators" [ngValue]="generator">{{ generator.generator_serial }}</option>
                    </select>
                </div>

                <div id="map" class="mb-3" *ngIf="generator"></div>

                <div class="form-row mb-3">
                    <div class="col">
                        <label class="control-label">Run hours <small *ngIf="required('run_hours')" class="text-muted">Required</small></label>
                        <input class="form-control" formControlName="run_hours" type="text" />
                    </div>
                    <div class="col">
                        <label class="control-label">Run cycles <small *ngIf="required('run_cycles')" class="text-muted">Required</small></label>
                        <input class="form-control" formControlName="run_cycles" type="text" />
                    </div>

                </div>

                <div formGroupName="customer">

                    <div class="form-group">
                        <label class="control-label">Customer name <small *ngIf="required('name', 'customer')" class="text-muted">Required</small></label>
                        <input class="form-control" formControlName="name" type="text" />
                    </div>

                    <!-- <div class="form-group">
                        <label class="control-label">Customer account</label>
                        <input class="form-control" formControlName="account" type="text" />
                    </div> -->

                </div>
            </div>

            <div class="col-sm-12 col-xl-4">

                <h3 class="form-group pt-3">Incident</h3>
                <div class="form-group">
                    <label class="control-label">Incident date <small *ngIf="required('incident_date')" class="text-muted">Required</small></label>
                    <!-- <input class="form-control" formControlName="installation_date" type="text"/> -->
                    <div class="input-group">
                        <input class="form-control" placeholder="yyyy-mm-dd" name="dp" formControlName="incident_date" ngbDatepicker #d="ngbDatepicker" [maxDate]="today">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary icon-calendar" (click)="d.toggle()" type="button"></button>
                        </div>
                    </div>
                </div>

                <!-- <div class="form-group">
                    <label class="control-label">Existing PIR</label>
                    <input class="form-control" formControlName="existing_pir" type="text" />
                </div> -->

                <div class="form-group">
                    <label class="control-label">Severity <small *ngIf="required('severity')" class="text-muted">Required</small></label>
                    <select class="form-control" formControlName="severity">
                        <option *ngFor="let severity of severities" [ngValue]="severity">{{ severity }}</option>
                    </select>
                </div>


                <div class="form-group">
                    <label class="control-label">Operating conditions <small *ngIf="required('operating_conditions')" class="text-muted">Required</small></label>
                    <textarea class="form-control" formControlName="operating_conditions" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1">
                        </textarea>
                </div>

                <div class="form-group">
                    <label class="control-label">Workpackage <small *ngIf="required('workpackage')" class="text-muted">Required</small></label>
                    <select class="form-control" formControlName="workpackage">
                        <option *ngFor="let workpackage of workpackages" [ngValue]="workpackage">{{ workpackage }}</option>
                    </select>
                </div>

                <div class="form-group">
                    <label class="control-label">Sub-system <small *ngIf="required('sub_system')" class="text-muted">Required</small></label>
                    <select class="form-control" formControlName="sub_system">
                        <option *ngFor="let subsystem of subsystems" [ngValue]="subsystem">{{ subsystem }}</option>
                    </select>
                </div>


                <div class="form-group">
                    <label class="control-label">Incident description <small *ngIf="required('incident_description')" class="text-muted">Required</small></label>
                    <textarea class="form-control" formControlName="incident_description" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1">
                    </textarea>
                </div>

                <div class="form-group">
                    <label class="control-label">Troubleshooting efforts <small *ngIf="required('troubleshooting_efforts')" class="text-muted">Required</small></label>
                    <textarea class="form-control" formControlName="troubleshooting_efforts" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1">
                    </textarea>
                </div>

                <div class="form-group">
                    <label class="control-label">Corrective action <small *ngIf="required('corrective_action')" class="text-muted">Required</small></label>
                    <textarea class="form-control" formControlName="corrective_action" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1">
                    </textarea>
                </div>

                <div class="form-group">
                    <label class="control-label">Short term fix <small *ngIf="required('short_term_fix')" class="text-muted">Required</small></label>
                    <textarea class="form-control" formControlName="short_term_fix" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1">
                    </textarea>
                </div>

            </div>


            <div class="col-sm-12 col-xl-4">

                <h3 class="form-group pt-3">Parts</h3>
                <p>Add details of any parts replaced</p>

                <textarea class="form-control" formControlName="parts" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2">
                </textarea>

                <!-- <div formArrayName="parts" class="mb-3" *ngFor="let part of incidentForm.get('parts').controls; let i = index;">
                    <div [formGroupName]="i" class="row">
                        <div class="col-sm-1">
                            <strong>{{ i+1 }}</strong>
                        </div>

                        <div class="col">
                            <div class="form-group">
                                <label for="number">Part number</label>
                                <button type="button" class="close" aria-label="Close" (click)="removePart(i)">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <input formControlName="number" placeholder="Part number" class="form-control">
                            </div>
                            <div class="form-group">
                                <label for="name">Part name</label>
                                <input formControlName="name" placeholder="Part name" class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <button class="btn btn-outline-secondary" (click)="addPart()">Add part</button>
                </div> -->


                <h3 class="form-group pt-3">Attachments</h3>
                <p>Upload documentation to support your incident report.</p>

                <file-upload-2 (upload)="addAttachment($event)"></file-upload-2>

                <div formArrayName="attachments" *ngFor="let attachment of incidentForm.get('attachments').controls; let i = index;">
                    <div [formGroupName]="i" class="form-group">

                        <div class="file d-flex align-items-center">
                            <span class="file-icon file-icon-{{attachment.value.extension}}"></span>
                            <div class="file-name">
                                <p class="mb-0">{{ attachment.value.name }} ({{ size(attachment.value.size) }})</p>
                            </div>
                            <div class="file-actions">
                                <button type="button" class="close" aria-label="Close" (click)="deleteAttachment(i)">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                            </div>
                        </div>


                    </div>

                </div>

                <h3 class="form-group pt-3">Photos</h3>
                <p>Photos to support your incident report.</p>
                <file-upload-2 class="mb-3" (upload)="addPhoto($event)"></file-upload-2>

                <div formArrayName="photos" *ngFor="let attachment of incidentForm.get('photos').controls; let i = index;">
                    <div [formGroupName]="i" class="form-group">

                        <div class="file d-flex align-items-center">
                            <span class="file-icon file-icon-{{attachment.value.extension}}"></span>
                            <div class="file-name">
                                <p class="mb-0">{{ attachment.value.name }} ({{ size(attachment.value.size) }})</p>
                            </div>
                            <div class="file-actions">
                                <button type="button" class="close" aria-label="Close" (click)="deleteAttachment(i)">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                            </div>
                        </div>


                    </div>

                </div>



            </div>
        </div>


    </form>
</div>