import { Ng2StateDeclaration } from '@uirouter/angular';
import { UserDetailComponent, UserListComponent, UserProfileComponent } from './'
import { loginResolve, permissionResolve } from '../app.states'

export const userListState: Ng2StateDeclaration = {
    name: 'users',
    url: '/users',
    views: {
        'app@': {
            component: UserListComponent
        }
    },
    data: { permission: 2},
    resolve: [loginResolve, permissionResolve]
}

export const newUserState: Ng2StateDeclaration = {
    name: 'users.new',
    url: '/new',
    data: { permission: 1},
    views: {
        'app@': {
            component: UserDetailComponent
        }
    },
    resolve: [loginResolve, permissionResolve]
}

export const userDetailsState: Ng2StateDeclaration = {
    name: 'users.details',
    url: '/:id',
    data: { permission: 1},
    views: {
        'app@': {
            component: UserDetailComponent
        }
    },
    resolve: [loginResolve, permissionResolve]
}

/** The top level state(s) */
export const STATES = [
    userListState,
    userDetailsState,
    newUserState
]