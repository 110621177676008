import { Injectable } from '@angular/core'; 
import { Observable, Subject } from 'rxjs'; 

@Injectable() 

export class ConfirmService {
private subject = new Subject<any>();

  constructor(){}

  confirm(message: string, confirmFn:()=>void, cancelFn:()=>void){
    this.setConfirmation(message, confirmFn, cancelFn);
  }

  setConfirmation(message: string, confirmFn:()=>void, cancelFn:()=>void) {
    
    this.subject.next({ 
      type: "confirm",
      title: message,
      confirmFn: ()=> {
        this.subject.next(); //this will close the modal
        confirmFn();
      },
      cancelFn: ()=> {
        this.subject.next();
        cancelFn();
      }
     });
  }

  getMessage(): Observable<any> {
     return this.subject.asObservable();
  }
}