import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class NavService {

  private _open: BehaviorSubject<boolean> = new BehaviorSubject(false)

  constructor() { }

  get open(){
    return this._open
  }
  toggle(){
    this._open.next(!this.open.value)
  }

  close(){
    
    this._open.next(false)
  }
}
