import { Component } from '@angular/core';
import { NavService } from './ui';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'app';
  _open: boolean;

  constructor(private navService: NavService) {
    this.navService.open.subscribe((value) => {
      this._open = value;
    });
  }

  get open() {
    return this._open;
  }
}
