import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Statuses, Permissions } from '../../data'
import _ from 'underscore'
import { ConfirmService } from '../../ui'
import { ToastrService } from 'ngx-toastr'
import { BusyService } from '../../ui'
import { MembrsService } from '@nakedcreativity/membrs-angular-helper'
var arraySort = require('array-sort');

const ListUsers = gql`
query retrieveUsers{
  retrieveUsers {
    firstname
    surname
    permission
    distributor{
      id
      name
    }
    status
    updated
    created
    id
  }
}
`
const DeleteUser = gql`
mutation deleteUser($id: ID!){
  deleteUser(id: $id){
    id
  }
}
`


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']

})

export class UserListComponent implements OnInit {

  private _users: any[]
  private _sortField: 'surname'
  private _sortReverse: boolean = false;

  constructor(private apollo: Apollo, private confirmService: ConfirmService, private toastr: ToastrService, private busy: BusyService, private membrs: MembrsService) {

  }

  ngOnInit() {
    this.busy.busy()
    this.apollo
      .watchQuery({
        query: ListUsers,
        errorPolicy: 'all'
      })
      .valueChanges.subscribe((result: any) => {
        this._users = result.data && result.data.retrieveUsers;
        this.busy.finished()
      });
  }

  get users() {
    return this._users
  }

 
  get admin(){
    return this.membrs.profile.data_permission == 1
  }
  get statuses(){
    return Statuses
  }
  get permissions(){
    return Permissions
  }

  delete(user) {
    
    this.confirmService.confirm('Are you sure you want to delete '+user.firstname+' '+user.surname+'?', ()=>{
      
      this.busy.busy()
      this.apollo.mutate({
        mutation: DeleteUser,
        variables: {
          id: user.id
        },
        refetchQueries: ['retrieveUsers']
      }).subscribe((result:any) => {
        this.toastr.success('Successfully deleted user')
        this.busy.finished()
      },(error) => {
        this.toastr.error(error.message)
        console.log('there was an error sending the query', error);
        this.busy.finished()
      });


    }, ()=>{})

  }

  sort(field){

    if(this._sortField == field){
      this._sortReverse = !this._sortReverse
    }else {
      this._sortReverse = false
    }
      
    this._sortField = field
    arraySort(this._users, field, {reverse: this._sortReverse});
    
  }


  sortClass(field){
    if(this._sortField == field && this._sortReverse)
      return 'reverse'
      
    if(this._sortField == field)
      return 'sort'
  }


}
