import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Countries, Statuses } from '../../data'
import _ from 'underscore'
import { BusyService } from '../../ui'
var arraySort = require('array-sort');

const ListDistributors = gql`
query retrieveDistributors{
  retrieveDistributors {
      id
      name
      status
      address {
        country
      }
      created
  }
}
`

@Component({
  selector: 'app-distributors-list',
  templateUrl: './distributors-list.component.html',
  styleUrls: ['./distributors-list.component.scss']
})
export class DistributorsListComponent implements OnInit {

  private _distributors: any[];
  loading = true;
  error: any;
  private _countriesByCode: any = {}
  private _sortField: 'surname'
  private _sortReverse: boolean = false;

  constructor(private apollo: Apollo, private busy: BusyService) { }

  ngOnInit() {
    this.busy.busy()
    
    _.each(Countries, (country: any) => {
      this._countriesByCode[country.code] = country
    })

    this.apollo
      .watchQuery({
        query: ListDistributors,
        errorPolicy: 'all'
      })
      .valueChanges.subscribe((result: any) => {
        //console.log(result)
        this._distributors = result.data && result.data.retrieveDistributors;
        _.each(this._distributors, distributor=>{
          // console.log(distributor)
          distributor.country = this.getCountryName(distributor.address.country)
        })
        this.busy.finished()
      });

  }

  getCountryName(code) {
    return this._countriesByCode[code] && this._countriesByCode[code].name
  }

  getStatusName(status) {
    return Statuses[status] && Statuses[status].name
  }

  get distributors() {
    return this._distributors
  }

  get actions() {
    return [
      {
        label: 'New Distributor',
        state: '.new'
      }

    ]
  }

  sort(field){

    if(this._sortField == field){
      this._sortReverse = !this._sortReverse
    }else {
      this._sortReverse = false
    }
      
    this._sortField = field
    arraySort(this._distributors, field, {reverse: this._sortReverse});
    
  }


  sortClass(field){
    if(this._sortField == field && this._sortReverse)
      return 'reverse'
      
    if(this._sortField == field)
      return 'sort'
  }

}
