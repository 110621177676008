<iframe src="https://api.bladonmt.com/cookie" style="display: none"> </iframe>
<div class="banner d-none d-md-block">
    <h1>Distributor Portal</h1>

    <div class="d-flex">
        <a class="icon-link" uiSref="publications">
            <img src="/assets/download-icon.svg" />
            <h2>All publications</h2>
        </a>

        <a class="icon-link" uiSref="warranty.registration">
            <img src="/assets/turbine-icon.svg" />
            <h2>Register generator</h2>
        </a>

        <a class="icon-link" uiSref="incidents.new">
            <img src="/assets/turbine-icon.svg" />
            <h2>New Product Incident Report</h2>
        </a>
    </div>
</div>

<h1 class="p-3 mt-3 d-md-none">Distributor Portal</h1>

<div class="d-flex py-3">
    <div class="col-sm-12 py-3">
        <h3 class="text-secondary">Recent publications</h3>

        <ul class="list-group list-group-flush">
            <li class="list-group-item d-flex align-items-center" *ngFor="let publication of publications">
                <a uiSref="publications.view" class="mr-2" [uiParams]="{ id: publication.id }">
                    {{ publication.title }}
                </a>
                {{ publication.published | date: 'd LLLL yyyy' }}

                <div class="ml-auto">
                    <span class="badge badge-secondary">
                        {{ publication.categories[0].name }}
                    </span>
                </div>
            </li>
        </ul>
    </div>
</div>
