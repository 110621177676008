 <div *ngIf="message" class="modal" tabindex="-1" role="dialog" style="display:block!important">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
 
            <div class="modal-header">
                <h3 class="modal-title">{{ message.title }}</h3>
            </div>   

            <div class="modal-footer">
                
                <button class="btn btn-primary" (click)="message.cancelFn()">No</button>
                <button class="btn btn-secondary" (click)="message.confirmFn()">Yes</button>
                        
            </div>
        </div>
    </div>
</div>