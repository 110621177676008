<h3 class="modal-header mb-0">User Profile</h3>

<div class="modal-body">
  <form class="form" [formGroup]="profileForm" autocomplete="off">

      <div class="form-group">
        <label class="control-label">First name</label>
        <input class="form-control" formControlName="firstname" type="text" />
      </div>

      <div class="form-group">
        <label class="control-label">Surname</label>
        <input class="form-control" formControlName="surname" type="text" />
      </div>

      <div class="form-group">
        <label class="control-label">Email</label>
        <input class="form-control" formControlName="email" type="text" />
      </div>
    
  </form>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" (click)="submit()">Save</button>
  <button class="btn btn-outline-secondary" (click)="cancel()">Cancel</button>
</div>