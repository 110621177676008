import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { NavService } from '../nav.service'

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit {

	@Input() title: string
	@Input() template: TemplateRef<any>;

  	constructor(private navService: NavService) { 

  	}

  	ngOnInit(){
		console.log(this.template)
  	}

	toggle(){
		this.navService.toggle()
	}

}
