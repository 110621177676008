<div class="container-fluid main">
	<div class="row align-items-start py-3">
		

		<div class="col col-auto pr-0 d-md-none"><button class="btn btn-outline-primary" (click)="toggle()">|||</button></div>
		<div class="col">
			<div class="row">
				<div class="col-sm h1 align-self-center">{{ title }}</div>

				<!-- <div class="col col-auto justify-end" *ngIf="searchVisible">
					<input type="text" class="form-control" placeholder="Search" [formControl]="searchControl"/>
				</div> -->

				<div class="col-sm-auto col-auto justify-end mt-sm-0 mt-2 ">
					<ng-container *ngTemplateOutlet="template"></ng-container>
				</div>
			</div>
		</div>
		
	</div>
</div>