import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { ToastrService } from 'ngx-toastr';
import { BusyService } from '../ui';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  private _publications: any = [];

  constructor(private apollo: Apollo, private busy: BusyService, private toastr: ToastrService, private http: HttpClient) {
    const options: Object = { responseType: 'text', observe: 'response' };
    this.http.get('https://api.bladonmt.com/cookie', options).subscribe((response) => console.log(response));
  }

  ngOnInit() {
    //this.busy.busy()
    this.apollo
      .watchQuery({
        query: ListPublications,
        errorPolicy: 'all',
        variables: {
          limit: 5,
          start_at: 0,
        },
      })
      .valueChanges.subscribe(
        (result: any) => {
          this._publications = result.data && result.data.retrievePublications;
          this.busy.finished();
        },
        (error) => {
          this.busy.finished();
          this.toastr.error(error.message);
        }
      );
  }

  get publications() {
    return this._publications;
  }
}

const ListPublications = gql`
  query listPublications($limit: Int, $start_at: Int) {
    retrievePublications(limit: $limit, start_at: $start_at) {
      id
      title
      published
      categories {
        name
      }
    }
  }
`;
